




















import { Component, Vue } from "vue-property-decorator";
import Button from "../components/commons/inputsAndControls/Button.vue";
import LoginLayout from "../components/commons/layouts/LoginLayout.vue";
import { Routes } from "../enums";

@Component({
  components: {
    Button,
    LoginLayout,
  },
})
export default class ChangedPasswordPage extends Vue {
  Routes = Routes;
}
